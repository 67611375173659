<template>
	<v-dialog
		v-model="dialog"
		:width="$vuetify.breakpoint.smAndDown ? '100%' : '540'"
		class="modal"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
			:ripple="false"
			:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
			depressed
			:small="$vuetify.breakpoint.smAndDown"
			class="black-btn mr-5 mr-md-10"
				v-bind="attrs"
				v-on="on"
			>
				<span
					class="vue-editor font-weight-bold px-md-7 px-8 white--text"
					v-html="trans('otc_platform_subheader_btn')"
				/>
			</v-btn>
		</template>
		<FormComponent />
	</v-dialog>
</template>

<script>
import FormComponent from '@/components/dialogs/contact-sales-dialog/form-component/FormComponent'

export default {
	name: 'SubheaderBtn',
	components: {
		FormComponent
	},
	data: () => ({
		dialog: false
	}),
	mounted () {
		this.$root.$on('closeModalWindow', () => {
			this.dialog = false
		})
	}
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}

</style>
