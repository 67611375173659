<template>
  <v-btn
    :ripple="false"
    :height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
    depressed
    :small="$vuetify.breakpoint.smAndDown"
    class="black-btn mr-md-10"
		@click="goToPage"
	>
    <span
			class="vue-editor font-weight-bold white--text"
			v-html="trans('otc_platform_last_left_btn')"
    />
  </v-btn>
</template>

<script>
export default {
	name: 'GetSecureLeftBtn',
	methods: {
		goToPage () {
			if (this.$route.name !== 'WelcomePage') {
				this.$router.push({
					name: 'WelcomePage'
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.black-btn {
  border-radius: 30px !important;
  background: var(--v-black-base) !important;
  color: white !important;
  transition: all 0.4s;
	min-width: 190px !important;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.9) !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.black-btn {
		min-width: 170px !important;
	}
}

</style>
