<template>
  <div class="content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" align-self="center">
        <h2
					class="vue-editor text-md-h2 text-body-1 text-center font-weight-black"
					v-html="trans('premium_exchange_page_get_secure')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-column flex-md-row justify-md-center align-center align-md-start">
				<GetSecureLeftBtn />
        <GetSecureRightBtn />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GetSecureLeftBtn from '@/components/premium-exchange/premium-exchange-buttons/GetSecureLeftBtn.vue'
import GetSecureRightBtn from '@/components/premium-exchange/premium-exchange-buttons/GetSecureRightBtn'

export default {
  name: 'GetSecure',
  components: {
    GetSecureLeftBtn,
		GetSecureRightBtn
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 150px;
  margin-bottom: 150px;
}
.sub-title {
  max-width: 667px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>
