<template>
<div
	class="main-image-container"
	:style="{
		width: imageWidth,
	}"
>
	<v-img
		width="94.74%"
		src="@/assets/images/charts-dashboard.png"
		class="dashboard"
	/>
	<v-img
		width="78.72%"
		src="@/assets/images/chart-1.png"
		class="chart-shadow chart-1"
	/>
	<v-img
		width="24.62%"
		src="@/assets/images/chart-2.png"
		class="footer-chart chart-2"
	/>
	<v-img
		width="24.62%"
		src="@/assets/images/chart-3.png"
		class="footer-chart chart-3"
	/>
	<v-img
		width="24.62%"
		src="@/assets/images/chart-4.png"
		class="footer-chart chart-4"
	/>
</div>
</template>

<script>
export default {
	name: 'Charts',
	props: {
		width: {
			type: [String, Number],
			default: 780
		}
	},
	computed: {
		imageWidth () {
			if (this.width[this.width.length - 1] === '%') {
				return this.width
			}
			else {
				return this.width + 'px'
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.main-image-container {
	position: relative;
	max-width: 780px;
}
.dashboard {
	filter: drop-shadow(-2px 20px 50px rgba($color: #2D3436, $alpha: 0.1));
}
.chart-1 {
	position: absolute !important;
	z-index: 10;
	top: 22.38%;
	left: 21.28%;
	filter: drop-shadow(0px 3px 10px rgba($color:  #2D3436, $alpha: 0.05));
}
.footer-chart {
	position: absolute;
	top: 80.59%;
	filter: drop-shadow(0px 3px 10px rgba($color:  #2D3436, $alpha: 0.05));
}
.chart-2 {
	left: 21.28%;
}
.chart-3 {
	left: 48.33%;
}
.chart-4 {
	right: 0;
}
</style>