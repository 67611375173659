<template>
	<div class="content">
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<h2
					:class="[
						'vue-editor text-md-h2 text-subtitle-1 font-weight-black title',
						isCutTitle ? 'cut-title' : ''
					]"
					v-html="title"
				/>
			</v-col>
		</v-row>
		<v-row no-gutters class="flex-column">
			<v-col cols="12">
				<div class="d-flex justify-space-between card-list flex-wrap">
					<div
						v-for="(item, index) in list"
						:key="index"
						class="instance-card"
					>
						<div class="instance d-flex align-center justify-center mb-6 mb-md-8">
							<v-img
								:src="item.src"
								width="38"
								height="38"
								contain
								class="icon"
							/>
						</div>
						<v-divider class="mb-6 mb-md-7" />
						<p
							class="vue-editor font-weight-bold mb-4"
							v-html="item.title"
						/>
						<p
							class="vue-editor text-body-1 grayText--text"
							v-html="item.description"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'CardRow',
	props: {
		title: {
			type: String,
			required: true
		},
		list: {
			type: Array,
			required: true
		},
		isCutTitle: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.title {
	margin-bottom: 100px !important;
}
.card-list {
	row-gap: 60px;
	column-gap: 24px;
}
.instance-card {
	max-width: 250px;
}
.instance {
	width: 82px;
	height: 82px;
	background: var(--v-mint-base);
	border-radius: 15px;
}
.icon {
	transition: all .4s;
  cursor: pointer;
	&:hover {
		transform: scale(1.1);
	}
}
.cut-title {
	max-width: 600px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.title {
		margin-bottom: 40px !important;
	}
}

@media #{map-get($display-breakpoints, 'xs-only')} {
	.instance-card {
		max-width: 100%;
	}
}
</style>
