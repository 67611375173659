import ApiStartAcceptCoinsCreateRequest from '@/api/start-accept-coins'
import CloseModal from '@/components/btns/CloseModal'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
	name: 'FormComponent',
	components: {
		CloseModal,
		VuePhoneNumberInput
	},
	data: () => ({
		phone: '',
		message: '',
		errors: {},
		isFormSent: false,
		valid: false,
		form: {
			name: '',
			email: '',
			phone: '',
			telegram_account: '',
			company_website: '',
			industry: '',
			region: '',
			crypto_payments_volume: '',
			use_purpose: ''
		},
		sending: false,
		index: 1,
		cryptoVolumeIndex: 1,
		isValidPhoneNumber: false
	}),
	computed: {
		purposesToUse () {
			return [
				{
					id: 1,
					name: this.trans('request_access_form_processing_payments_crypto'),
				},
				{
					id: 2,
					name: this.trans('request_access_form_processing_payments_crypto_fiat'),
				},
				{
					id: 3,
					name: this.trans('request_access_form_business_wallet'),
				},
				{
					id: 4,
					name: this.trans('request_access_form_exchange_otc'),
				},
				{
					id: 5,
					name: this.trans('request_access_form_purpose_item_5'),
				}
			]
		},
		cryptoPaymentsVolume () {
			return [
				{
					id: 1,
					name: this.trans('request_access_form_estimated_crypto_payments_item_1'),
				},
				{
					id: 2,
					name: this.trans('request_access_form_estimated_crypto_payments_item_2'),
				},
				{
					id: 3,
					name: this.trans('request_access_form_estimated_crypto_payments_item_3'),
				}
			]
		},
		regions () {
			return [
				{
					id: 1,
					name: this.trans('request_access_form_region_item_1'),
				},
				{
					id: 2,
					name: this.trans('request_access_form_region_item_2'),
				},
				{
					id: 3,
					name: this.trans('request_access_form_region_item_3'),
				},
				{
					id: 4,
					name: this.trans('request_access_form_region_item_4'),
				},
				{
					id: 5,
					name: this.trans('request_access_form_region_item_5'),
				},
				{
					id: 6,
					name: this.trans('request_access_form_region_item_6'),
				}
			]
		},
		nameRules () {
			return [
				v => v.length >= 2 || this.trans('request_access_form_name_required')
			]
		},
		companyRules () {
			return [
				v => v.length >= 2 || this.trans('request_access_form_company_name_required')
			]
		},
		themeRules () {
			return [
				v => v.length >= 2 || this.trans('request_access_form_topic_required')
			]
		},
		emailRules () {
			const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
			return [
				v => !!v || this.trans('request_access_form_email_is_required'),
				v => emailFilter.test(v) ||
					this.trans('request_access_form_email_must_valid'),
				v => !(/[а-яА-ЯЁё]/.test(v)) || this.trans('request_access_form_email_must_not_cyrillic')
			]
		},
		// urlRules () {
		// 	const urlFilter = /^(ftp|http|https):\/\/[^ "]+$/
		// 	return [
		// 		v => urlFilter.test(v) || this.trans('request_access_form_url_must_valid'),
		// 	]
		// },
		phoneInputTranslations () {
			return {
				countrySelectorLabel: this.cleanTextFromTags(this.trans('request_access_form_phone_country_code')),
				phoneNumberLabel: this.cleanTextFromTags(this.trans('request_access_form_phone_field') + '*'),
				example: this.cleanTextFromTags(this.trans('request_access_form_phone_number_example') + ': ')
			}
		}
	},
	methods: {
		setPhoneNumber (data) {
			this.isValidPhoneNumber = data.isValid
			if (data.isValid) {
				this.form.phone = data.formatInternational
			}
		},
		setCryptoValue (value) {
			this.form.crypto_payments_volume = value
			this.cryptoVolumeIndex = 1
		},
		setRegion (value) {
			this.form.region = value
			this.index = 1
		},
		submit () {
			this.sending = true
			if (!this.$refs.form.validate()) return
			this.addEmails()
			ApiStartAcceptCoinsCreateRequest.create(this).then(response => {
				this.message = this.trans(response.data.message)
				this.sending = false
				this.isFormSent = true
			}).catch(err => {
				this.showErrors(err.response.data.errors)
			})
		},
		addEmails () {
			if (!this.state.emails && !this.state.emails.length) return
			let email = this.state.emails.find(item => item.key === 'request_access')
			this.form.from = email.email_from
			this.form.admin_email = email.email_to
		},
		close () {
			this.form = {
				name: '',
				email: '',
				phone: '',
				telegram_account: '',
				company_website: '',
				industry: '',
				region: '',
				crypto_payments_volume: '',
				use_purpose: ''
			}
			this.isFormSent = false
			this.$root.$emit('closeModalWindow')
		},
		showErrors (errors) {
			this.errors = errors
		},
		clearError (field) {
			this.errors[field] = ''
		}
	}
}
