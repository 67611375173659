<template>
	<v-dialog
		v-model="dialog"
		:width="$vuetify.breakpoint.smAndDown ? '100%' : '540'"
		class="modal"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				:ripple="false"
				:height="$vuetify.breakpoint.smAndDown ? '40' : '60'"
				depressed
				:small="$vuetify.breakpoint.smAndDown"
				class="black-btn mt-5 mt-md-0"
				v-bind="attrs"
				v-on="on"
			>
				<span
					class="vue-editor font-weight-bold white--text"
					v-html="trans('otc_platform_last_btn')"
				/>
			</v-btn>
		</template>
		<FormComponent />
	</v-dialog>
</template>

<script>
import FormComponent from '@/components/dialogs/accept-coins-dialog/form-component/FormComponent'

export default {
	name: 'GetSecureLeftBtn',
	components: {
		FormComponent
	},
	data: () => ({
		dialog: false
	}),
	mounted () {
		this.$root.$on('closeModalWindow', () => {
			this.dialog = false
		})
	}
}
	</script>

<style lang="scss" scoped>
.black-btn {
	border-radius: 30px !important;
	background: var(--v-black-base) !important;
	color: white !important;
	transition: all 0.4s;
	min-width: 190px !important;

	&:hover {
		background: rgba($color: #000000, $alpha: 0.9) !important;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.black-btn {
		margin-left: auto;
		margin-right: auto;
		min-width: 170px !important;
	}
}

</style>
