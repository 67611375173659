import Charts from '@/components/image-components/Charts'
import Subheader from '@/components/premium-exchange/subheader/Subheader.vue'
import CardRow from '@/components/card-row/CardRow.vue'
import GetSecure from '@/components/premium-exchange/GetSecure'

export default {
  name: 'PremiumExchange',
  components: {
    Charts,
    Subheader,
    CardRow,
    GetSecure
  },
	metaInfo () {
		return {
			title: 'Crypto OTC Trading Platform | 1TN',
			meta: [{
				name: 'description',
				content: 'Credible and transparent OTC desk facilitates the trading, buying, and selling of your digital assets at best exchange rate.',
			},
      {
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Crypto OTC Trading Platform | 1TN',
			},
			{
				name: 'og:description',
				content: 'Credible and transparent OTC desk facilitates the trading, buying, and selling of your digital assets at best exchange rate.',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Crypto OTC Trading Platform | 1TN',
			},
			{
				name: 'twitter:description',
				content: 'Credible and transparent OTC desk facilitates the trading, buying, and selling of your digital assets at best exchange rate.',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
    ],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
  computed: {
    items () {
      return [
        {
          src: require('@/assets/images/eu-icon.svg'),
          title: this.trans('premium_exchange_page_choose_us_item_1_title'),
          description: this.trans('premium_exchange_page_choose_us_item_1_content'),
        },
        {
          src: require('@/assets/images/limits-icon.svg'),
          title: this.trans('premium_exchange_page_choose_us_item_2_title'),
          description: this.trans('premium_exchange_page_choose_us_item_2_content'),
        },
        {
          src: require('@/assets/images/white-list-icon.svg'),
          title: this.trans('premium_exchange_page_choose_us_item_3_title'),
          description: this.trans('premium_exchange_page_choose_us_item_3_content'),
        },
        {
          src: require('@/assets/images/reporting-icon.svg'),
          title: this.trans('premium_exchange_page_choose_us_item_4_title'),
          description: this.trans('premium_exchange_page_choose_us_item_4_content'),
        }
      ]
    }
  }
}
