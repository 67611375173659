export default {
	name: 'ImageCaptcha',
	data: () => ({
		CHARS_LIST: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
		LENGTH: 6,
		captcha: [],
		key: null,
		inputValue: ''
	}),
	mounted () {
		this.createCaptcha()
	},
	computed: {
		isValidate () {
			return this.inputValue === this.key
		}
	},
	methods: {
		createCaptcha () {
			document.getElementById('captcha').innerHTML = ''
			this.createKey()
			this.createCanvas()
		},
		createKey () {
			for (let i = 0; i < this.LENGTH; i++) {
				let index = Math.floor(Math.random() * this.CHARS_LIST.length + 1)
				if (this.captcha.indexOf(this.CHARS_LIST[index]) == -1)
					this.captcha.push(this.CHARS_LIST[index])
				else i--
			}
		},
		createCanvas () {
			let canv = document.createElement('canvas')
			canv.id = 'captcha'
			canv.width = 100
			canv.height = 50
			let ctx = canv.getContext('2d')
			ctx.font = '25px Georgia'
			ctx.strokeText(this.captcha.join(''), 0, 30)
			this.key = this.captcha.join('')
			document.getElementById('captcha').appendChild(canv)
		},
		setStatus () {
			this.$emit('set-status', this.isValidate)
		}
	}
}
