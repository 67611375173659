import ApiContactSalesRequest from '@/api/contact-sales'
import CloseModal from '@/components/btns/CloseModal'
import ImageCaptcha from '@/components/image-captcha/ImageCaptcha'

export default {
	name: 'FormComponent',
	components: {
		CloseModal,
		ImageCaptcha
	},
	data: () => ({
		message: '',
		errors: {},
		isFormSent: false,
		valid: false,
		form: {
			name: '',
			email: '',
			company: '',
			website: '',
			discuss_theme: '',
			message: ''
		},
		fileWarn: '',
		sending: false,
		checkbox: false,
		captchaStatus: false
	}),
	computed: {
		nameRules () {
			return [
				v => v.length >= 2 || this.trans('contact_us_form_name_required')
			]
		},
		companyRules () {
			return [
				v => v.length >= 2 || this.trans('contact_us_form_company_name_required')
			]
		},
		discussThemeRules () {
			return [
				v => v.length >= 2 || this.trans('contact_us_form_discuss_theme_required')
			]
		},
		emailRules () {
			const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
			return [
				v => !!v || this.trans('contact_us_form_email_is_required'),
				v => emailFilter.test(v) ||
					this.trans('email_must_valid'),
				v => !(/[а-яА-ЯЁё]/.test(v)) || this.trans('contact_us_form_email_must_not_cyrillic')
			]
		},
		// urlRules () {
		// 	const urlFilter = /^(ftp|http|https):\/\/[^ "]+$/
		// 	return [
		// 		v => urlFilter.test(v) || this.trans('url_must_valid'),
		// 	]
		// }
	},
	methods: {
		submit () {
			if (!this.$refs.form.validate()) return

			this.addEmails()
			this.sending = true
			ApiContactSalesRequest.create(this).then(response => {
				this.message = this.trans(response.data.message)
				this.sending = false
				this.isFormSent = true
			}).catch(err => {
				this.showErrors(err.response.data.errors)
			})
		},
		addEmails () {
			if (!this.state.emails && !this.state.emails.length) return
			let email = this.state.emails.find(item => item.key === 'contact_us')
			this.form.from = email.email_from
			this.form.admin_email = email.email_to
		},
		close () {
			this.form = {
				name: '',
				email: '',
				company: '',
				website: '',
				discuss_theme: '',
				message: '',
				linked_in: '',
			}
			this.checkbox = false
			this.isFormSent = false
			this.$root.$emit('closeModalWindow')
		},
		showErrors (errors) {
			this.errors = errors
		},
		clearError (field) {
			this.errors[field] = ''
		},
		setStatus (val) {
			this.captchaStatus = val
		}
	}
}
